<template>
  <div>
    <a-button size="large" type="primary" class="login-button" @click="loginBtn"
      >登录</a-button
    >
  </div>
</template>

<script>
import { getLoginUrl } from '@/api/user'

export default {
  name: 'Login',
  data() {
    return {}
  },
  methods: {
    loginBtn() {
      getLoginUrl().then((res) => {
        if (res.code === 0) {
          window.location.href = res.data.url
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.login-button {
  display: block;
  margin: 60px auto;
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 368px;
}
</style>
